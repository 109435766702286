import { useContext, useEffect, useRef, useState } from 'react';
import '../styles/Skulls.css';
import skull from '../images/skulls/skull.png'
import diamond from '../images/skulls/almaz.png'
import { Link } from "react-router-dom";
import '../styles/Skulls.css'

import info1 from '../images/skulls/info1.webp'
import info2 from '../images/skulls/info2.jpg'
import info3 from '../images/skulls/info3.jpg'
import info4 from '../images/skulls/info4.jpg'
import info5 from '../images/skulls/info5.webp'
import info6 from '../images/skulls/info6.jpg'





function Skulls() {
    const [showModal, setShowModal] = useState(false);
    const [animateSkulls, setAnimateSkulls] = useState(false);
    const [raiseSkullIndex, setRaiseSkullIndex] = useState();
    const [showResSpan,setShowResSpan]=useState(false);

    const [hashInput, setHashInput] = useState(''); // Хранение значения input
    const [error, setError] = useState(''); // Хранение сообщения об ошибке

    const [showInfo,setShowInfo] = useState(false);
    const handleStartClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleStartShowInfo = () => {
        setShowInfo(true);
    };

    const handleCloseShowInfo = () => {
        setShowInfo(false);
    };



    const handleOkClick = () => {

        if (hashInput.length < 120) { 
            return;
        }

        else{
            setShowResSpan(false)
            setShowModal(false);
            setAnimateSkulls(true);
            setTimeout(() => {
                setAnimateSkulls(false);
                // Выбираем случайный череп для поднятия
                setRaiseSkullIndex(Math.floor(Math.random() * 3));
                console.log(raiseSkullIndex)
                setShowResSpan(true)
            }, 5000); // Анимация движения длится 5 секунд
        }
    };

    return (
        <div className="app">
            <div className='back' onClick={handleStartShowInfo}>ОБУЧЕНИЕ ИГРЕ</div>
            <div className='div_result'>
            {raiseSkullIndex === 0 && showResSpan&&
                <span className='result_skull'>Левый череп</span>                        
            }

            {raiseSkullIndex === 1 && showResSpan&&
                <span className='result_skull'>Центральный череп</span>                        
            }

            {raiseSkullIndex === 2 && showResSpan&&
                <span className='result_skull'>Правый череп</span>                        
            }       
            </div>

                <div className='div_skulls'>   
                        <div className='div_skull'>
                            <img
                                src={skull} // Замените на фактический URL изображения черепа
                                className={`skull ${animateSkulls ? 'animate_left' : ''} ${raiseSkullIndex === 0 ? 'raise' : ''}`}
                                alt="skull"
                            />    
                            {raiseSkullIndex === 0 &&
                                
                                <img src={diamond} className={ `${animateSkulls?'display_none':'diamond'}`} alt="diamond" />
                                                      
                            }
                        </div>   
                        <div className='div_skull'>
                        <img
                            src={skull} // Замените на фактический URL изображения черепа
                            className={`skull ${animateSkulls ? 'animate_center' : ''} ${raiseSkullIndex === 1 ? 'raise' : ''}`}
                            alt="skull"
                        />    
                            {raiseSkullIndex === 1 && <img src={diamond} className={ `${animateSkulls?'display_none':'diamond'}`} alt="diamond" />}
                        </div>
                        <div className='div_skull'>
                            <img
                                src={skull} // Замените на фактический URL изображения черепа
                                className={`skull ${animateSkulls ? 'animate_right' : ''} ${raiseSkullIndex === 2 ? 'raise' : ''}`}
                                alt="skull"
                            />    
                            {raiseSkullIndex === 2 && <img src={diamond} className={ `${animateSkulls?'display_none':'diamond'}`} alt="diamond" />}
                        </div>                                                             
            </div>

            <div className="button-container">
                 
                <div className="skulls_button_link link" onClick={handleStartClick}>
                    <span className="span_button_link">Начать</span>
                </div>        

                    <Link to="/main" >  
                        <div className="skulls_button_link link">
                            <span className="span_button_link">Назад</span>
                        </div>   

                        
                    </Link>                           

            </div>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-header">
                            <span className='game_hash_modal_window'>GAME HASH ID</span>
                            <button className="close-button" onClick={handleCloseModal}>×</button>
                        </div>
                        <div className="modal-body">
                            <input type="text" placeholder="Введите hash ID" className='input_modal' onChange={(e) => setHashInput(e.target.value)}/>
                        </div>
                        <div className="modal-footer">
                            <button className="button" onClick={handleOkClick}>OK</button>
                        </div>
                    </div>
                </div>
            )}


            {showInfo && (
                <div className="modal-overlay-lacky">
                    <div className="modal-lacky">
                        <div className="modal-header-lacky">
                            <span className='nameGameSkulls'>FAQ: Brawl Pirates</span>
                            <button className="close-button color_x" onClick={handleCloseShowInfo}>×</button>
                        </div>

                    <div className='rules'>
                    <p>Ищем игру в разделе 1win games, вот её иконка:</p>
                        <img src={info1} ></img>
                        
                        <h4>О САМОЙ ИГРЕ</h4>
                        <p>Brawl Pirates - это игра в пиратской тематике, основной целью которой является угадать, под каким из трех черепов спрятаны сокровища. Механика игры знакома многим, так как она является новым взглядом на классическую игры в наперстки.</p>
                        <p>Суть игры предельна проста - после того как под один из трех черепов кладется рубин и они перемешиваются необходимо выбрать тот, под которым по вашему мнению лежит сокровище.</p>
                        <p>Если череп угадан правильно, то ваша ставка умножается, если нет - ставка теряется. Размер множителя, на который будет умножена ваша ставка в результате выигрыша можно увидеть на панели управления игрой справа.</p>
                        <p>Вы также можете нажать на множитель чтобы изменить режим игры, добавив дополнительный рубин под черепа. В этом режиме игры в Brawl Pirates шансы на победу значительно выше, но и итоговый приз будет меньше.</p>
                        <p>Также в игре есть помощник с выбором варианта. В тот момент когда вы не знаете что лучше выбрать, можно нажать на игральную кость на панели управления слева. Она случайным образом предложит вам один из вариантов.</p>

                        <p>Но помните, варианты выбираются случайным образом, точно так же как и выигрышный череп.</p>

                        <h4>КАК РАБОТАТЬ ПО СИГНАЛАМ?</h4>
                        <p>В начале заходим в саму игру и делаем одну ставку на 10 рублей (минимальная ставка):</p>
                        <img src={info2} className='info_img'></img>
                        <p>Выбираем любой череп, после чего копируем Hash игры:</p>
                        <img src={info3} className='info_img'></img>
                        <p>Далее переходим уже в бота и нажимаем на кнопку НАЧАТЬ, и вставляем полученный ранее Hash в поле:</p>
                        <img src={info4} className='info_img'></img>
                        <p>Нажимаем на ОК и получаем сам сигнал:</p>
                        <img src={info5} className='info_img'></img>
                        <p>В самой игре выбираем уже вашу рабочую ставку (в моем случае - 1000 рублей) и нажимаем на нужный череп:</p>
                        <img src={info6} className='info_img'></img>

                        <p>ВСЁ! Вы научились правильно играть по сигналам</p> 

                    </div>

                    </div>
                </div>
            )}


        </div>
    );
}


export default Skulls;
  