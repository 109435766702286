import '../styles/LackyJet_style.css'
import signal from '../images/lacky/buttonlucky.svg'
import pers from '../images/lacky/pers.gif'
import { useContext, useEffect, useState } from 'react';
import persfly from '../images/lacky/persfly.gif'
import { Link } from 'react-router-dom';
import { languages } from '../language';
import { Context } from '../context';
import '../styles/LackyJet_style.css'
import info1 from '../images/lacky/info1.jpg'
import info2 from '../images/lacky/info2.svg'
import info3 from '../images/lacky/info3.webp'
import info4 from '../images/lacky/info4.jpg'
import info5 from '../images/lacky/info5.jpg'


function LackyJet() {
    const [counter, setCounter] = useState(100); // Состояние для хранения текущего значения счетчика
    const [startCounting, setStartCounting] = useState(false); // Состояние для отслеживания начала отсчета
    const [step, setStep] = useState(0);
    const [random,setRandom] = useState(0);
    const [showingAnimation, setShowingAnimation] = useState(false)
    const [showingPers, setShowingPers] = useState(true)
    const [currentTime, setCurrentTime] = useState(new Date())
    const [showingTime, setShowingTime] = useState(false)

    const [remainingTime, setRemainingTime] = useState(60); 

    const [showModal, setShowModal] = useState(false);
    
    const {lang,setlang} = useContext(Context)
    const data=languages[lang]

    const [generationCount, setGenerationCount] = useState(0);


    const handleStartClick = () => {
      setShowModal(true);
  };

  const handleCloseModal = () => {
      setShowModal(false);
  };


const generateRandomNumber = () => {

  let randomNumber;

  if (generationCount < 5) { // Если генерация меньше 5 раз, генерируем число от 101 до 125
      randomNumber = Math.floor(Math.random() * (145 - 110 + 1)) + 110;
  } else { // Иначе генерируем от 120 до 360
      randomNumber = Math.floor(Math.random() * (360 - 120 + 1)) + 120;
  }

  console.log(randomNumber)
  console.log(generationCount)

  setRandom(randomNumber); // Устанавливаем новое случайное число
  setGenerationCount(prevCount => prevCount + 1); // Увеличиваем счетчик генераций
};

    // Функция для запуска отсчета по клику
    const handleStart = () => {
      setRemainingTime(60)
        setStartCounting(true); // Запускаем отсчет
        setShowingAnimation(true); // Показываем анимацию
        setShowingPers(false);
        setTimeout(() => {            
            setShowingAnimation(false); // Скрываем анимацию после завершения
        }, 1250);
        const newTime = new Date(); // Создаем новый объект времени
        newTime.setMinutes(newTime.getMinutes() + 3); // Прибавляем к минутам три
        setCurrentTime(newTime);

        const intervalId = setInterval(() => {
          setRemainingTime(prevTime => {
            const updatedTime = prevTime - 1; // Уменьшаем время на 1 секунду
            if (updatedTime <= 0) {
              clearInterval(intervalId); // Останавливаем интервал, если время истекло
            }

            return updatedTime;
          });
        }, 1000);
    };


       // Функция для запуска отсчета по клику
       const handleStart_two = () => {
         generateRandomNumber();
         setCounter(0)
//
          setRemainingTime(60)

          setStartCounting(true); // Запускаем отсчет
          setShowingPers(false);
          const newTime = new Date(); // Создаем новый объект времени
          newTime.setMinutes(newTime.getMinutes() + 3); // Прибавляем к минутам три
          setCurrentTime(newTime);
  
          const intervalId = setInterval(() => {
            setRemainingTime(prevTime => {
              const updatedTime = prevTime - 1; // Уменьшаем время на 1 секунду
              if (updatedTime <= 0) {                
                clearInterval(intervalId); // Останавливаем интервал, если время истекло
              }
              return updatedTime;
            });
          }, 1000);
      };

      
    useEffect(() => {
        generateRandomNumber(); // Вызываем функцию генерации рандомного числа при монтировании компонента

    },[]);

    useEffect(() => {
        let intervalId;
        
        if (startCounting) {
          // Интервал для увеличения счетчика каждые 100 миллисекунд
          intervalId = setInterval(() => {
            if (counter < random) { // Увеличиваем до 500, чтобы остановиться на 5.00                
              setCounter(prevCounter => prevCounter + step);
               //setStep(prevStep => prevStep + 0.05); 
               setStep(prevStep => prevStep + 0.4); 
            }
            else{
                setShowingTime(true);
            }
          }, 100);

        } // Каждые 100 миллисекунд (или другое удобное вам значение)
        
        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);;
      }, [startCounting, counter, step]);

      const gradientStyle = {
        background: 'linear-gradient(to right, #F4D04B, #D46D50)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textDecoration: 'none'
    };


  return (
    <div className="lackyJetBack"> 
      <div className='divWithLogoAndQuestion background-color_question_lucky' onClick={handleStartClick}>
        <span> ОБУЧЕНИЕ ИГРЕ </span>       
      </div>   
     
        

       {showingPers && ( // Показываем анимацию, если showingAnimation равна true
            <img src={pers}></img>
        )}
        
        {showingAnimation && ( // Показываем анимацию, если showingAnimation равна true
                <img src={persfly} alt="Animation" />
        )}
        { startCounting && !showingAnimation && ( // Показываем счетчик, если startCounting равно true и showingAnimation равно false  startCounting &&
               <div>
                <span className='X'> X</span>
                <span className='counter'>{(counter / 100).toFixed(2)}</span>
                </div>  
        )}

        {!startCounting && ( // Показываем сигнал, если startCounting равно false
            <img src={signal} alt="Signal" onClick={handleStart} style={{width:200}}/>
        )}

        {showingTime && remainingTime!=0 && (
            <div className='next_pusk' >
                <div className='timedivstart'>
                  <span className='timestartUp'> {data.timeStartMines}  </span>
                  <span className='timestartDown'> {remainingTime%60} {data.seconds} </span>
                </div> 
            </div>
        )}

        {showingTime &&remainingTime!=0 &&(
          <div class="progress-bar">
            <div class="progress-bar-fill"></div>
          </div>
        )}

        {remainingTime===0 && ( // Показываем сигнал, если startCounting равно false
            <img src={signal} alt="Signal" onClick={handleStart_two} style={{width:200}}/>
        )}

        {showModal && (
                <div className="modal-overlay-lacky">
                    <div className="modal-lacky">
                        <div className="modal-header-lacky">
                            <span className='nameGameLacky'>FAQ: LuckyJet</span>
                            <button className="close-button close_button_color_lacky" onClick={handleCloseModal}>×</button>
                        </div>

                      <div className='rules'>
                        <p>Ищем игру в разделе 1win games, вот её иконка:</p>
                        <img src={info1} className='info_img'></img>
                        
                        <h4>О САМОЙ ИГРЕ</h4>
                        <p>Lucky Jet – новейшая краш-игра, которая подойдет новому поколению игроков. Ты можешь выиграть в несколько раз больше от суммы ставки буквально за несколько секунд! Lucky Jet устроен на схеме которую можно проверить и которая в настоящий момент считается единственной реально работающей гарантией честности в индустрии.</p>
                        <p> Коэффициент каждого раунда (момент при котором пилот реактивного ранца Улетел!) генерируется не на игровых серверах. Наоборот генерация коэффициента определяется с помощью игроков-участников конкретного раунда. Причем этот процесс полностью прозрачен. Следовательно ни у кого не возникает возможности для манипуляции результатами раунда. Более того у каждого игрока имеется возможность проверить и убедиться в честности расчета коэффициента.Подробное объяснение принципа работы:
                        Результат раунда генерируется на клиентах четырех разных и независимых игроков которые участвуют в конкретном раунде.</p>
                        <img src={info2} className='info_img'></img>
                        
                        
                        
                        <h4>Как играть и какие правила?</h4>
                        <p>Чтобы сделать ставку нужно выбрать желаемую сумму и нажать на кнопку «Ставка».</p>
                        <p>При этом нет нужды ограничивать себя только одной ставкой за раз. Вы можете делать две ставки одновременно используя как левую так и правую панель ставки.
                        Чтобы вывести выигрыш нужно нажать кнопку «Вывод». Ваш выигрыш складывается из совокупности вашей ставки умноженной на множитель кэшаута.</p>
                        <p>Если не сделать Вывод до того как Счастливчик Джо улетит то ставка будет потеряна.</p>
                        <h4>КАК РАБОТАТЬ ПО СИГНАЛАМ?</h4>
                        <p>Нажимаем на кнопку PUSH, после чего получаем сигнал:</p>
                        <img src={info3} className='info_img'></img>
                        
                        <p>Этот сигнал показывает на каком значение коэффициента во время полета Джета, на котором нужно забрать.</p>
                        <p>Делаем ставку с галочкой на Автовывод и ставим нужный нам параметр:</p>
                        <img src={info4} className='info_img'></img>
                        
                        <p>После чего делаем нашу ставку, а игра автоматически её заберет на нужном коэффициенте:</p>
                        <img src={info5} className='info_img'></img>
                        
                        <p>ВСЁ! Вы научились правильно играть по сигналам</p>
                      </div>

                    </div>
                </div>
            )}


  <Link to="/main" className="menu_jet" style={{ textDecoration: 'none' }}>
              <span className='menu_rotate'>↺</span>
              <span style={gradientStyle} > {data.menu} </span>
  </Link>
    </div>
  );
}

export default LackyJet;