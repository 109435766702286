
import '../styles/Mines_style.css'
import '../images/maines/results/1.png'
import { useContext, useEffect, useRef, useState } from 'react';
import initial from '../images/maines/initial.png'


import res1 from '../images/maines/7/1.mp4'
import res2 from '../images/maines/7/2.mp4'
import res3 from '../images/maines/7/3.mp4'
import res4 from '../images/maines/7/4.mp4'
import res5 from '../images/maines/7/5.mp4'
import res6 from '../images/maines/7/6.mp4'
import res7 from '../images/maines/7/7.mp4'
import res8 from '../images/maines/7/8.mp4'
import res9 from '../images/maines/7/9.mp4'
import res10 from '../images/maines/7/10.mp4'


import res11 from '../images/maines/5/1.mp4'
import res12 from '../images/maines/5/2.mp4'
import res13 from '../images/maines/5/3.mp4'
import res14 from '../images/maines/5/4.mp4'
import res15 from '../images/maines/5/5.mp4'
import res16 from '../images/maines/5/6.mp4'
import res17 from '../images/maines/5/7.mp4'
import res18 from '../images/maines/5/8.mp4'
import res19 from '../images/maines/5/9.mp4'
import res20 from '../images/maines/5/10.mp4'


import res21 from '../images/maines/3/1.mp4'
import res22 from '../images/maines/3/2.mp4'
import res23 from '../images/maines/3/3.mp4'
import res25 from '../images/maines/3/5.mp4'
import res26 from '../images/maines/3/6.mp4'
import res27 from '../images/maines/3/7.mp4'
import res28 from '../images/maines/3/8.mp4'
import res29 from '../images/maines/3/9.mp4'
import res30 from '../images/maines/3/10.mp4'

import res31 from '../images/maines/1/1.mp4'
import res32 from '../images/maines/1/2.mp4'
import res33 from '../images/maines/1/3.mp4'
import res34 from '../images/maines/1/4.mp4'
import res35 from '../images/maines/1/5.mp4'
import res36 from '../images/maines/1/6.mp4'
import res37 from '../images/maines/1/7.mp4'
import res38 from '../images/maines/1/8.mp4'
import res39 from '../images/maines/1/9.mp4'
import res40 from '../images/maines/1/10.mp4'


import new1 from '../images/maines/7/новые/new1.mp4'
import new2 from '../images/maines/7/новые/new2.mp4'
import new3 from '../images/maines/7/новые/new3.mp4'
import new4 from '../images/maines/7/новые/new4.mp4'
import new5 from '../images/maines/7/новые/new5.mp4'


import info1 from '../images/maines/info1.jpg'
import info2 from '../images/maines/info2.webp'
import info3 from '../images/maines/info3.jpg'
import info4 from '../images/maines/info4.jpg'



import { Link } from 'react-router-dom';



function Mines() {
    const [launchCount, setLaunchCount] = useState(0);
    const [videoSrc, setVideoSrc] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [caption, setCaption] = useState("");
    const[numberOfTraps,setNumberOfTraps]=useState(1);

    const videoRef = useRef(null);

    // Массив видео
    const videoListMines1 = [
    res31,
    res32,
    res33,
    res34,
    res35,
    res36,
    res37,
    res38,
    res39,
    res40,
    ];

    const videoListMines3 = [
        res21,
        res22,
        res23,
        res25,
        res26,
        res27,
        res28,
        res29,
        res30,
    ];

    const videoListMines5 = [
        res11,
        res12,
        res13,
        res14,
        res15,
        res16,
        res17,
        res18,
        res19,
        res20,
    ];


    const videoListMines7 = [

        res1,
        res2,
        res3,
        res4,
        res5,
        res6,
        res7,
        res8,
        res9,
        res10,

        res11,
        res12,
        res13,
        res14,
        res15,
        res16,
        res17,
        res18,
        res19,
        res20,


        res21,
        res22,
        res23,
        res25,
        res26,
        res27,
        res28,
        res29,
        res30,

        new1,
        new2,        
        new3,
        new4,
        new5,
    ];







    const [showModal, setShowModal] = useState(false);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [showWarning, setShowWarning] = useState(false);

    const handleStartClick = () => {
        setShowModal(true);
    };
  
    const handleCloseModal = () => {
        setShowModal(false);
    };





    const getRandomVideo = () => {
        if (launchCount < 5) {
            return { src: videoListMines7[Math.floor(Math.random() * videoListMines7.length)], caption: "" };
        } else {
            let videoArray, captionText;
            const randChoice = Math.floor(Math.random() * 3);
            if (randChoice === 0) {
                videoArray = videoListMines1;
                //captionText = "Режим 3 ловушки";
                captionText = "3";
            } else if (randChoice === 1) {
                videoArray = videoListMines3;
                //captionText = "Режим 5 ловушек";
                captionText = "5";
            } else {
                videoArray = videoListMines5;
                //captionText = "Режим 7 ловушек";
                captionText = "7";
            }
            return { src: videoArray[Math.floor(Math.random() * videoArray.length)], caption: captionText };
        }
    };

    const playVideo = () => {


        const currentTime = Date.now();
        if (currentTime - lastClickTime < 5000) { // Проверка на интервал 5 секунд
            
            setShowWarning(true);
            setTimeout(() => setShowWarning(false), 2000); // Скрыть предупреждение через 2 секунды
            return;
        }
        else{
            setNumberOfTraps(Math.random() < 0.5 ? 1 : 3);
        }

        setLastClickTime(currentTime);
        setLaunchCount(prevCount => prevCount + 1);
        const { src, caption } = getRandomVideo();
        setVideoSrc(src);
        setCaption(caption);
        setShowVideo(true);
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video && videoSrc) {
            video.currentTime = 0;
            video.play().catch(error => {
                console.error('Ошибка воспроизведения видео:', error);
            });

            const handleVideoEnd = () => {
                video.pause();
                video.currentTime = video.duration; // Остановка на последнем кадре
            };

            video.addEventListener('ended', handleVideoEnd);
            return () => {
                video.removeEventListener('ended', handleVideoEnd);
            };
        }
    }, [videoSrc]);

    return (
        <div className='container'>
            <div className='obuchenie'>
                <span className='text_with_question_mines' onClick={handleStartClick}> ОБУЧЕНИЕ ИГРЕ </span>
                
            </div>
            
            {showModal && (
                <div className="modal-overlay-lacky">
                    <div className="modal-lacky">
                        <div className="modal-header-lacky">
                            <span className='nameGameMines'>FAQ: Mines</span>
                            <button className="close-button close_button_color_mines" onClick={handleCloseModal}>×</button>
                        </div>
                        <div className='rules'>
                            <p>Ищем игру в разделе 1win games, вот её иконка:</p>
                            <img src={info1} className='info_img_mines'></img>

                            <h4>О САМОЙ ИГРЕ</h4>

                            <p>Mines - это гэмблинг игра, которая основывается на классическом “Сапёре”. Ваша цель - открывать безопасные ячейки и не попадаться в ловушки. </p>
                            <p>Введите сумму ставки и выберите количество ловушек на поле. Нажмите кнопку “Играть”, чтобы сделать ставку и начать игру.</p>
                            <p>В каждой ячейке находится либо звездочка, либо крестик-ловушка. Каждая открытая звездочка повышает выигрышный коэффициент, а крестик-ловушка - завершает игру.</p>
                            <p>Вы всегда можете проверить честность генерируемых исходов. В истории ставок хранятся все данные завершенных игр. Правильность хеширования можно проверить в любом онлайн хэш-генераторе.</p>

                            <h4>КАК РАБОТАТЬ ПО СИГНАЛАМ?</h4>
                            <p>Нажимаем на кнопку ПОЛУЧИТЬ СИГНАЛ, и видим:</p>
                            <img src={info2} className='info_img_mines'></img>

                            <p>После чего выбираем то количество ловушек, которое нам указал бот (в моем случае - 3, все видно на скрине):</p>
                            <img src={info3} className='info_img_mines'></img>

                            <p>Далее закрываем клетки как показано на вашем сигнале:</p>
                            <img src={info4} className='info_img_mines'></img>

                            <p>ВСЁ! Вы научились правильно играть по сигналам</p>
                        </div>
                    </div>
                </div>
            )}
            {showWarning && (
                <div className="warning-message caption">
                    <div style={{display:'flex', justifyContent:'center'}}>Пожалуйста, подождите 5 секунд перед следующим нажатием!</div>
                    
                </div>
            )}
            {!showVideo ? (
                <img src={initial} alt="Initial" className="video" />
            ) : (
                <div>
                    <video
                        ref={videoRef}
                        src={videoSrc}
                        muted
                        className="video"
                        loop={false}
                        webkit-playsinline="true"
                        playsInline
                        style={{ objectFit: "cover" }}
                        onLoadedMetadata={(e) => e.target.play()}
                    />
                </div>
            )}
            <div className='menu_quantity_mines'>
                {caption ? 
                <div>
                    <span className="number_of_traps caption"> {caption}</span>
                    <span className="traps">Ловушек</span>
                </div>
                :
                <div>
                    <span className="number_of_traps caption"> {numberOfTraps}</span>
                    <span className="traps">Ловушек</span>
                </div>}
            </div>
            <div className='button_signal' onClick={playVideo}>
                <span className='span_button_signal'>Получить сигнал</span>
            </div>
            <Link to="/main" className="menu_mines" style={{ textDecoration: 'none' }}>
                <span className='menu_rotate_mines'>↺</span>
                <span>МЕНЮ</span>
            </Link>
        </div>
    );
}
  
export default Mines;