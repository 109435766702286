import { Link } from "react-router-dom";
import '../styles/Main.css'


function Main() {


  return (

    <div className="mainModel">
      <div className="container_link">


        <div className="link">
          <div className="img_link background_image_lacky"></div>
            <span className="span_under_image">Lucky jet</span>
            <Link to="/lacky">  
              <div className="button_link link">
                <span className="span_button_link">Открыть</span>
              </div>        
            </Link>
        </div>


        <div className="link">
          <div className="img_link background_image_mines"></div>
            <span className="span_under_image">Mines</span>
            <Link to="/mines">  
              <div className="button_link link">
                <span className="span_button_link">Открыть</span>
              </div>        
            </Link>
        </div>


        <div className="link">
          <div className="img_link background_image_skulls"></div>
            <span className="span_under_image">BRAWL PIRAETS</span>
            <Link to="/skulls">  
              <div className="button_link link">
                <span className="span_button_link">Открыть</span>
              </div>        
            </Link>
        </div>

      </div>

    </div>
  );
}

export default Main;